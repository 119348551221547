import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.filter(
    edge => edge.node.frontmatter.type === "blog"
  );
  return (
    <Layout>
      <SEO title="Home" />

      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <div key={node.fields.slug}>
            <h3>
              <Link key={node.fields.slug} to={node.fields.path}>
                {node.frontmatter.title}
              </Link>
            </h3>
            <small>{node.frontmatter.date}</small>
            <p
              dangerouslySetInnerHTML={{
                __html: node.excerpt
              }}
            />
          </div>
        );
      })}
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            type
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
  }
`;
